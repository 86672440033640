<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
            />

            <!-- 3d character -->
            <div class="d-flex align-center justify-center h-full pa-16 pe-0 text-center">
              <v-img
                contain
                max-width="50%"
                height="500"
                class="auth-3d-group"
                :src="require(`@/assets/images/logos/logo_eradar.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Market Analysis System
                  </p>
                  <p class="mb-2">
                    Please sign-in to your account
                  </p>
                </v-card-text>

                <v-card-text>
                  <v-form
                    ref="loginForm"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="Email"
                      :error-messages="errorMessages.email"
                      :rules="[validators.required, validators.emailValidator]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      :error-messages="errorMessages.password"
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <div class="d-flex align-center justify-space-between flex-wrap">
                      <!-- <v-checkbox
                        hide-details
                        label="Remember Me"
                        class="mt-0"
                      >
                      </v-checkbox> -->

                      <!-- forget link -->
                      <!-- <router-link
                        :to="{name:'auth-forgot-password'}"
                        class="ms-3"
                      >
                        Forgot Password?
                      </router-link> -->
                    </div>

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                    >
                      Log In
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import Swal from 'sweetalert2'

// import axios from '@axios'
import ClientOAuth2 from 'client-oauth2'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'

export default {
  setup() {
    // Template Ref
    const loginForm = ref(null)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)

    const config = {
      clientId: `${process.env.VUE_APP_CLIENT_ID}`,
      clientSecret: `${process.env.VUE_APP_CLIENT_SECRET}`,
      accessTokenUri: `${process.env.VUE_APP_AUTH_SERVER_URL}/protocol/openid-connect/token`,
      userInfoUri: `${process.env.VUE_APP_AUTH_SERVER_URL}/protocol/openid-connect/userinfo`,
      scopes: ['acr', 'email', 'profile', 'roles', 'web-origins'],
    }

    const oauth = new ClientOAuth2(config)

    const email = ref('')
    const password = ref('')
    const errorMessages = ref([])
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return
      const em = email.value
      const pw = password.value

      oauth.owner.getToken(em, pw)
        .then(response => {
          const { accessToken } = response

          // ? Set access token in localStorage so axios interceptor can use it
          // Axios Interceptors: https://github.com/axios/axios#interceptors
          localStorage.setItem('accessToken', accessToken)
          const decodedToken = jwt_decode(accessToken)

          let userAbility = ref([])

          if (decodedToken.roles.includes('administrator')) {
            decodedToken.role = 'ADMINISTRATION'
            userAbility = [
              {
                action: 'manage', // data.address, // 'manage',
                subject: 'all',
              },
            ]
          } else {
            decodedToken.role = 'CLIENT'
            userAbility = [
              {
                action: 'read', // data.address, // 'manage',
                subject: decodedToken.role, // 'all',
              },
            ]
          }

          // Set user ability
          // ? https://casl.js.org/v5/en/guide/intro#update-rules
          vm.$ability.update(userAbility)

          // Set user's ability in localStorage for Access Control
          localStorage.setItem('userAbility', JSON.stringify(userAbility))

          // We will store `userAbility` in localStorage separate from userData
          // Hence, we are just removing it from user object
          delete decodedToken.ability

          // Set user's data in localStorage for UI/Other purpose
          localStorage.setItem('userData', JSON.stringify(decodedToken))

          // Checamos variables por defecto.
          const equipo = JSON.parse(localStorage.getItem('marketSelected'))
          const status = JSON.parse(localStorage.getItem('statusSelected'))

          if (equipo == null) {
            const equipoSelected = { value: 'SIERRA-VISTA' }
            localStorage.setItem('marketSelected', JSON.stringify(equipoSelected))
          }

          if (status == null) {
            const statusSelected = { value: 'Active' }
            localStorage.setItem('statusSelected', JSON.stringify(statusSelected))
          }

          // On success redirect to home
          router.push('/')

          // return response
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to login!')
          console.log('error :>> ', error)

          // errorMessages.value = error.response.data.error

          Swal.fire({
            text: 'Error in credentials, check user and password field',
            toast: true,
            position: 'top-right',
            icon: 'error',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
          })
        })
    }

    return {
      handleFormSubmit,

      isPasswordVisible,
      email,
      password,
      errorMessages,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
